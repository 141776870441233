.navbar-brand img {
  height: 35px;
}

.bg-whisper {
  background-color: #f8f8f8;
}

.border-whisper {
  border-color: #e7e7e7;
}

/* boostrap overrides */
.page-item.active .page-link {
  background-color: #00415e;
}

* {
  font-family: "Open Sans", sans-serif;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

.bg-whisper {
  background-color: #eee;
}

.bg-skyblue {
  background-color: #89b2cd;
}

.bg-darkblue {
  background-color: #00415e;
}

.bg-black {
  background-color: black;
}

.bg-linear-gradient-darkblue {
  background: rgb(0, 100, 155);
  background: linear-gradient(
    90deg,
    rgba(0, 100, 155, 1) 0%,
    rgba(0, 71, 103, 1) 97%
  );
}

.bg-prussian-blue {
  background: #00415e;
}

label,
h2,
h3,
h4,
h5 {
  color: #00649b;
}

.text-cerulean {
  color: #00649b;
}

.text-skyblue {
  color: #89b2cd;
}

.text-prussian-blue {
  color: #00415e;
}

/* We should override bs .btn class */
.nw-btn {
  border-radius: 5px;
  font-weight: bold;
  min-width: 225px;
}

.nwg-heading {
  font-weight: 700;
}

 
.nw-btn:hover, .btn:hover {
    background-color:#00415e ; 
    color:#FFF; 
}

.btn-light {
  background-color: #eee;
  color: #00415e;
}

.btn-skyblue {
  background-color: #89b2cd;
  color: #fff;
}

.btn-prussian-blue {
  background-color: #00415e;
}

.btn-matterhorn {
  background-color: #535353;
}

.btn-ghost {
  background-color: #ced4da;
  color: black;
  border-color: #ced4da;
}
  
.btn-cerulean {
  background-color: #00649b;
}


/* Use bg-whisper */
.lease-general {
  background-color: #eee;
}

@media only screen and (min-width: 600px) {
  #flf label.form-label {
    min-height: 72px;
  }
}

.popover {
  max-width: 200px;
  width: 200px;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, 0%);
  background: #fff;
  border: 1px solid #00415e;
  color: #00415e;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.4;
  word-break: break-word;
}

.popover-arrow::before {
  border-bottom-color: #00415e !important;
}


/* Tables */
.table {
    --bs-table-striped-color: #00649B;
    --bs-table-striped-bg: #F6F6F6;
    --bs-table-hover-color: #00649B;
}

table > thead > tr {
    background-color: var(--bs-table-striped-bg);
}
table > thead > tr > th  {
    color: #333;
}

table > tbody {
    color: #00649B !important; 
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
}

.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
}